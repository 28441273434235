<template>
  <div>
    <div>
      <p v-html="page || 'NO_PAGE'"></p>
    </div>
    <div v-if="false" style="width: 50mm;">
      <table width="100%" border="0" cellpadding="0" cellspacing="0"><tbody><tr class="tr_title"><td colspan="3">ШАШЛЫК   shashlyc.kz</td></tr><tr class="tr_head"><td colspan="3" align="center">шашлык суши пицца чикен фастфуд<br>@shashlyc/www.shashlyc.kz<br>Tel.: +77024214272, +77081587415<br></td></tr><tr class="tr"><td colspan="3">
        <br>11.05.2024 23:31<br>№ 92</td></tr><tr class="tr"><td colspan="3"></td></tr><tr class="tr_border"><td><b>Наименование</b></td><td width="40" align="center"><b>Кол.</b></td><td width="50" align="right"><b>Сумма</b></td></tr><tr class="tr" valign="top"><td align="left">Coca Cola 1L</td><td align="center">1</td><td align="right">500</td></tr><tr class="tr" valign="top"><td align="left">Бонаква 1л (негаз)</td><td align="center">1</td><td align="right">300</td></tr><tr class="tr_sep"><td colspan="3"></td></tr><tr class="tr_sep"><td colspan="3"></td></tr><tr class="tr"><td align="left"><b>К ОПЛАТЕ</b></td><td></td><td align="right"><b>800</b></td></tr><tr class="tr"><td align="left" сolspan="3">Способ оплаты: Б/нал.</td></tr><tr class="tr_promo" valign="middle"><td align="center" colspan="3">Приятного аппетита!</td></tr><tr class="tr"><td colspan="3"></td></tr><tr><td colspan="3"><table border="0" cellspacing="0" cellpadding="0"></table></td></tr></tbody></table>
    </div>
    <div v-if="false" class="p-4">
      <h4 class="mb-2">
        Layout Blank
      </h4>
      <b-alert
          variant="primary"
          show
      >
        <div class="alert-body">
          <p>
            <strong>Info: </strong>
            <span>This layout is used in Authentication &amp; Miscellaneous page. Please check the </span>
            <b-link
                class="alert-link"
                href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/guide/layout/page-layout-examples.html#layout-blank"
                target="_blank"
            >Layout Blank documentation</b-link>
            <span> for more details.</span>
          </p>
        </div>
      </b-alert>
      <div>
        <p>Object param: {{prmObject}}</p>
        <p>Record param: {{prmRecord}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { BAlert, BLink } from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsRecordPage',
  components: {
    BAlert,
    BLink,
  },
  data() {
    return {
      prmObject: this.$route.params.object,
      prmRecord: this.$route.params.record,
      page: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.load()
      useCubus.setPageTitle('Чек Заказа')
    },
    load() {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'recordpage',
          param: {
            object: this.prmObject,
            record: this.prmRecord,
          },
        },
      })
        .then(response => {
          if (response.data.thread) this.threadLoad(response.data.thread)
        })
        .catch(errQry => {
          console.log('query error', errQry)
        })
    },
    delayLoad(threadName) { setTimeout(() => this.threadLoad(threadName), 500) },
    threadLoad(threadName) {
      useJwt.query({
        // token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'thread',
          param: {
            threadname: threadName,
          },
        },
      })
        .then(response => {
          console.log('thread response', response)
          if (response.data.thread && response.data.thread.status === 'done' && response.data.thread.result) {
            this.page = response.data.thread.result.page
          } else if (response.data.thread && response.data.thread.status === 'error') {
            console.log('thread error', response.data.thread.error)
            useCubus.toastError(this, response.data.thread.error)
          } else {
            this.delayLoad(threadName)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
  },
}
</script>

<style scoped>

</style>
